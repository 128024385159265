// import JSCookie from 'js-cookie'
import apiTools from '@/api'
import { jsFn } from '@/utils/js-fn'

export default {
    namespaced: true,
    state: {
        id_token: '',
        userInfo: '',
        userExtend: null
    },
    getters: {
        getIdToken: state => state.id_token,
        getUserInfo: state => state.userInfo,
        getUserExtend: state => state.userExtend
    },
    mutations: {
        setIdToken: function(state, idToken) {
            state.id_token = idToken
        },
        setUserInfo: function(state, info) {
            state.userInfo = info
        },
        setUserExtend: function(state, data) {
            state.userExtend = data
        }
    },
    actions: {
        setIdToken(context, params) { // 設定IdToken
            context.commit('setIdToken', params)
        },
        login(context, params) { // 登入
            return new Promise((resolve, reject) => {
                apiTools.coreApi.login(params).then(res => {
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        access_token: res.access_token,
                        expires_in: res.expires_in
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        codeLogin(context, params) { // 快速登入
            return new Promise((resolve, reject) => {
                apiTools.coreApi.codeLogin(params).then(res => {
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        access_token: res.access_token,
                        expires_in: res.expires_in
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        authLogin(context, params) { // AUTH登入
            return new Promise((resolve, reject) => {
                apiTools.coreApi.authLogin('', params).then(res => {
                    if(res.id_token) {
                        context.dispatch('setSession', {
                            id_token: res.id_token,
                            access_token: res.access_token,
                            expires_in: res.expires_in
                        })
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        bindThird(context, params) { // 後端綁定
            return new Promise((resolve, reject) => {
                apiTools.coreApi.authLogin(context.state.id_token, params).then(res => {
                    let info = context.state.userInfo
                    if(info === '') info = {}
                    info[params.grant_type] = true
                    context.dispatch('setSession', {
                        info: res.info,
                        id_token: res.id_token
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        crtUser(context, params) { // 建立帳號
            return new Promise((resolve, reject) => {
                apiTools.coreApi.crtProfile(params).then(res => {
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        access_token: res.access_token,
                        expires_in: res.expires_in
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        sendOTPCode(context, params) { // 發送驗證碼
            return new Promise((resolve, reject) => {
                apiTools.coreApi.sendOTPCode(params.type, params.data).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getUserInfo(context) { // 取得個人資訊
            return new Promise((resolve, reject) => {
                apiTools.coreApi.getUserInfo(context.state.id_token).then(res => {
                    let data = res
                    let jwtData = jsFn.jwtDecode(res.id_token)
                    data.picture = jwtData.picture
                    data.id = jwtData.sub
                    data.name = jwtData.name
                    data.lang = jwtData.lang
                    context.dispatch('setSession', {
                        info: data
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getUserExtend(context) { // 取得個人資訊
            return new Promise((resolve, reject) => {
                apiTools.coreApi.getUserExtend(context.state.id_token).then(res => {
                    context.dispatch('setSession', {
                        extend: res
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        setUserInfo(context, params) { // 設定使用者
            context.commit('setUserInfo', params)
        },
        setUserExtend(context, params) { // 設定使用者進階資訊
            context.commit('setUserExtend', params)
        },
        restPassword(context, params) { // 重置密碼
            return new Promise((resolve, reject) => {
                apiTools.coreApi.restPw(params).then(res => {
                    context.dispatch('setSession', {
                        access_token: res.access_token,
                        expires_in: res.expires_in,
                        id_token: res.id_token
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updName(context, name) { // 修改名稱
            return new Promise((resolve, reject) => {
                apiTools.coreApi.updName(context.state.id_token, name).then(res => {
                    let info = context.state.userInfo
                    info.name = name
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        info: info
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updPw(context, params) { // 修改密碼
            return new Promise((resolve, reject) => {
                apiTools.coreApi.updPw(context.state.id_token, params).then(res => {
                    let info = context.state.userInfo
                    info.is_set_pw = true
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        info: info
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updMobileMail(context, params) { // 修改EmailOrMobile
            return new Promise((resolve, reject) => {
                apiTools.coreApi.updMobileMail(context.state.id_token, params.data).then(res => {
                    let info = context.state.userInfo
                    if (params.email) {
                        info.mail = params.email
                    } else {
                        info.country = params.country
                        info.mobile = params.mobile
                    }
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        info: info
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updPicture(context, base64File) { // 修改頭貼
            return new Promise((resolve, reject) => {
                apiTools.coreApi.updPicture(context.state.id_token, base64File).then(res => {
                    let info = context.state.userInfo
                    let jwtData = jsFn.jwtDecode(res.id_token)
                    info.picture = jwtData.picture
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        info: info
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        unBind(context, provider) { // 解綁
            return new Promise((resolve, reject) => {
                apiTools.coreApi.unBind(context.state.id_token, provider).then(res => {
                    let info = context.state.userInfo
                    info[provider] = false
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        info: info
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        deleteAccount(context, pw) { // 刪除帳號
            return new Promise((resolve, reject) => {
                apiTools.coreApi.delAccount(context.state.id_token, pw).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        updLang(context, lang) { // 修改慣用語系
            return new Promise((resolve, reject) => {
                apiTools.coreApi.updLang(context.state.id_token, lang).then(res => {
                    let info = context.state.userInfo
                    info.lang = lang
                    context.dispatch('setSession', {
                        id_token: res.id_token,
                        info: info
                    })
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        setDefNoticeLang(context, lang) { // 設定通知預設語系
            return new Promise((resolve, reject) => {
                apiTools.coreApi.updLang(context.state.id_token, lang).then(res => {
                    context.dispatch('setSession', {
                        id_token: res.id_token
                    })
                    resolve(true)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        getSchoolData(context, item) { // 取得學校列表
            return new Promise((resolve, reject) => {
                apiTools.coreApi.getSchoolData(item).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        setUserContact(context, item) { // 設定進階資訊
            return new Promise((resolve, reject) => {
                apiTools.coreApi.setContact(context.state.id_token, item).then(res => {
                    context.dispatch('setSession', {
                        extend: item
                    })

                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        setSession(context, item) {
            if (!jsFn.isEmpty(item.id_token)) { // IdToken
                sessionStorage.setItem('accountState', encodeURIComponent(JSON.stringify({ loginFlag: true, id_token: item.id_token }), 'utf-8'))
                context.commit('setIdToken', item.id_token)
            }

            if (!jsFn.isEmpty(item.info)) { // 個人資訊
                sessionStorage.setItem('userInfo', encodeURIComponent(JSON.stringify(item.info), 'utf-8'))
                context.commit('setUserInfo', item.info)
            }

            if (!jsFn.isEmpty(item.extend)) { // 個人進階資訊
                sessionStorage.setItem('userExtend', encodeURIComponent(JSON.stringify(item.extend), 'utf-8'))
                context.commit('setUserExtend', item.extend)
            }

            if (!jsFn.isEmpty(item.access_token)) { // 權限Token
                sessionStorage.setItem('access_token', item.access_token)
            }

            if (!jsFn.isEmpty(item.expires_in)) { // 權限時間
                sessionStorage.setItem('expires_in', item.expires_in)
            }
        }
    }
}
