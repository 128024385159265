export default {
    apiUrl: {
        global: 'https://api2.teammodel.net',
        cn: 'https://api2.teammodel.cn',
        globalrc: 'https://api2-rc.teammodel.net',
        cnrc: 'https://api2-rc.teammodel.cn'
    },
    clientID: {
        global: 'c8de822b-3fcf-4831-adba-cdd14653bf7f',
        cn: '516148eb-6a38-4657-ba98-a3699061937f',
        globalrc: 'c8de822b-3fcf-4831-adba-cdd14653bf7f',
        cnrc: '516148eb-6a38-4657-ba98-a3699061937f'
    },
    qrCodeUrl: {
        global: 'https://qrcode.teammodel.net',
        cn: 'https://qrcode.teammodel.cn'
    },
    communityUrl: function(provider, redirectUri) {
        let result
        switch (provider) {
            case 'facebook':
                result = 'https://www.facebook.com/v19.0/dialog/oauth?scope=public_profile,email&redirect_uri=' + redirectUri + '&response_type=code&client_id=1382704845111595&display=popup&state=facebook'
                break
            case 'google':
                result = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&redirect_uri=' + redirectUri + '&response_type=code&client_id=30604537950-2e6eojbqt5k1kjg43jdvdq6g3gp0eo33.apps.googleusercontent.com&display=popup&state=google'
                break
            case 'wechat':
                result = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxd88617b770d4a9f4&redirect_uri=' + redirectUri + '&response_type=code&scope=snsapi_login&state=wechat#wechat_redirect'
                break
            case 'ding':
                result = 'https://oapi.dingtalk.com/connect/qrconnect?appid=dingrucgsnt8p13rfbgd&response_type=code&scope=snsapi_login&state=ding&redirect_uri=' + redirectUri
                break
            case 'educloudtw':
                result = 'https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=' + redirectUri + '&scope=openid+email+profile+eduinfo&state=educloudtw&nonce=aaaaa'
                // 教育雲測試站 // result = 'https://oidc.sso.edu.tw/oidc/v1/azp?response_type=code&client_id=9d8bfb91c18efafd423433d6d21ca2f3&redirect_uri=' + redirectUri + '&scope=openid+email+profile&state=educloudtw&nonce=aaaaa'
                break
        }
        return result
    },
    getApiUrl: function() {
        if (process.env.NODE_ENV === 'development') {
            return this.apiUrl.globalrc
        } else {
            let hostname = window.location.hostname
            let isCN = hostname.substr(hostname.length - 3) === '.cn'
            let serverLocation = isCN ? 'cn' : 'global'
            let subTtitle = window.location.hostname.indexOf('account-rc') >= 0 ? 'rc' : window.location.hostname.indexOf('coreaccountv2-test') >= 0 ? 'test' : ''
            return this.apiUrl[serverLocation + subTtitle]
        }
    },
    getQRCodeUrl: function() {
        if (process.env.NODE_ENV === 'development') {
            return this.qrCodeUrl.global
        } else {
            let hostname = window.location.hostname
            let isCN = hostname.substr(hostname.length - 3) === '.cn'
            let serverLocation = isCN ? 'cn' : 'global'
            return this.qrCodeUrl[serverLocation]
        }
    }
}
