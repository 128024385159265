import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { jsFn } from '@/utils/js-fn'

// i18n
import en from './lang/en.json'
import tw from './lang/tw.json'
import cn from './lang/cn.json'

Vue.use(VueI18n)

// 預設抓取瀏覽器的語系
var pageLang = jsFn.isEmpty(localStorage.lang) ? navigator.language.toLowerCase() : localStorage.lang
// 預設為英文版
if (pageLang !== 'zh-tw' && pageLang !== 'zh-cn') {
  pageLang = 'en-us'
}

localStorage.lang = pageLang

const i18n = new VueI18n({
  locale: pageLang,
  messages: {
    'zh-tw': tw,
    'zh-cn': cn,
    'en-us': en
  }
})

export default i18n
