//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import complete from '@/assets/img/complete.svg'
import attention from '@/assets/img/attention.svg'
import { jsFn } from '@/utils/js-fn'
import profile from '@/utils/profile'

export default {
    name: 'auth',
    data() {
        const validatePass = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else if (!(/^(?=.*[a-zA-Z])(?=.*\d)[\S]{8,30}$/.test(value))) {
                callback(new Error(this.$t('error_5')))
            } else {
                if (value === this.updPwdform.oldPw) {
                    return callback(new Error(this.$t('error_19')))
                }

                if (this.updPwdform.passwdCheck !== '') {
                    // 对第二个密码框单独验证
                    this.$refs.updPwdform.validateField('passwdCheck')
                }
                callback()
            }
        }
        const validatePassCheck = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_10')))
            } else if (value !== this.updPwdform.newPw) {
                callback(new Error(this.$t('error_11')))
            } else {
                callback()
            }
        }
        return {
            isCN: jsFn.isCN(), // 是否為大陸站
            isHabookaclass: jsFn.isHabookaclass(), // 是否domain為habookaclass
            redirectUrlByThird: window.location.origin + '/management/auth',
            updPWModal: false,
            delModal: false,
            lastOneProvider: '',
            modal_loading: false,
            updPwdform: {
                oldPw: '',
                newPw: '',
                passwdCheck: ''
            },
            updPwdRule: {
                newPw: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                passwdCheck: [
                    { validator: validatePassCheck, trigger: 'blur' }
                ]
            },
            openHitaAlert: false,
            bindCommunity: {
                flag: false,
                provider: 'facebook'
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo',
            pageLang: 'getLang',
            isHiTA_UA: 'isHiTA_UA',
            isAClassONE_UA: 'isAClassONE_UA',
            isApp: 'isApp'
        })
    },
    created: function() {
        // 第三方資訊
        let code = this.$route.query.code === undefined ? '' : this.$route.query.code
        let state = this.$route.query.state === undefined ? '' : this.$route.query.state

        if (!jsFn.isEmpty(code) && !jsFn.isEmpty(state)) { // 社群帳號登入
            this.putUserCode(code, state)
        }

        // 手機取得的第三方登入資訊
        let sociallogin = this.$route.query.sociallogin === undefined ? '' : this.$route.query.sociallogin

        // 手機取得的第三方登入資訊 暫時註解晚點處理
        if (!jsFn.isEmpty(sociallogin)) {
            this.appToBind(sociallogin)
        }
    },
    methods: {
        ...mapActions(['logOut']),
        unBindService: function(provider) {
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/unBind', provider).catch(e => {
                this.delModal = true
            }).finally(() => {
                this.bindCommunity.flag = false
                this.$Spin.hide()
            })
        },
        ctrBind_community: function(provider) {
            this.bindCommunity.flag = true
            this.bindCommunity.provider = provider
        },
        bind_community: function(provider) {
            // 待HiTA 更新
            if (provider === 'wechat' && (this.isHiTA_UA || this.isAClassONE_UA)) {
                this.bindWechat()
            } else {
                document.location.href = profile.communityUrl(provider, this.redirectUrlByThird)
            }
        },
        appToBind: function(sociallogin) {
            this.$Spin.show() // 開啟加載畫面
            this.$api.SocialByApp(this.loginID, sociallogin).then(res => {
                if (res.status === 0) {
                    this.$Spin.hide() // 關閉加載畫面
                    this.restUserInfo(res.data)
                }
            }).catch(err => {
                if (err.status === 400) {
                    this.$Spin.hide() // 關閉加載畫面
                    this.loading = false
                    this.$Message.warning({
                        content: this.$t('error_26'),
                        duration: 7
                    })
                    this.restUserInfo()
                }
            })
        },
        restUserInfo: function(data) {
            if (data) {
                data.loginFlag = true
                this.setUserInfo(data)
            }

            let backName = 'auth'
            this.$router.push({ name: backName })
        },
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面
                    // 修改密碼
                    let data = {
                        old_pw: this.updPwdform.oldPw,
                        new_pw: this.updPwdform.newPw
                    }
                    this.$store.dispatch('coreApi/updPw', data).then(() => {
                        this.updPWModal = false
                        this.updPwdform.oldPw = ''
                        this.updPwdform.newPw = ''
                        this.updPwdform.newPwCheck = ''
                        this.unBindService(this.lastOneProvider)
                    }).catch((e) => {
                        let text = this.$t('error_18')
                        switch (e.error) {
                            case 1:
                                text = this.$t('error_37')
                                break
                        }
                        this.$Message.warning({
                            content: text,
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide()
                    })
                }
            })
        },
        putUserCode: function(code, state) { // 如果第三方登入會啟動的function
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/bindThird', {
                grant_type: state,
                open_code: code,
                redirect_uri: this.redirectUrlByThird
            }).catch(() => {
                this.$Message.warning({
                    content: this.$t('auth_16'),
                    duration: 5,
                    closable: true
                })
            }).finally(() => {
                this.$Spin.hide()
                this.$router.push({ name: 'auth' })
            })
        },
        delAccount() {
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/deleteAccount').then(res => {
                this.delModal = false
                 this.logOut()
            }).catch(() => {
                this.$Message.warning({
                    content: this.$t('error_37'),
                    duration: 5,
                    closable: true
                })
            }).finally(() => {
                this.$Spin.hide()
            })
        },
        bindWechat() {
            let product = this.isHiTA_UA ? 'HiTA' : ''
            this.$api.GetTicket(this.loginID, product).then(res => {
                if (res.status === 0) {
                    this.openHitaAlert = false
                    window.location.href = 'hita://wechatbind?ticket=' + res.data
                } else {
                    console.log(res.apiResult)
                }
            })
        },
        isBind(provider) {
            let flag = this.userInfo[provider]
            return {
                flag: flag,
                icon: flag ? complete : attention
            }
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    }
}
