import { post } from '@/api/http'
import { jsFn } from '@/utils/js-fn'
import profile from '@/utils/profile'
var serverLocation = jsFn.isCN() ? 'cn' : 'global'
let hostname = window.location.hostname
if(hostname.indexOf('localhost') === 0 || hostname.indexOf('-rc') > 0) {
   serverLocation += 'rc'
}

var clientID = profile.clientID[serverLocation]
var apiUrl = profile.getApiUrl()
export default {
    // 取得快速登入CODE
    getCode: function(idToken, isTicket) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let type = isTicket ? 'ticket' : 'code'
            let data = {
                grant_type: type,
                client_id: clientID,
                nonce: nonceStr,
                id_token: idToken
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res[type])
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 登入驗證
    login: function(item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            // 開頭有0要去0
            let re = /^[0-9]+$/
            if (re.test(item.id) && item.id.toString().indexOf(0) === 0) {
                item.id = item.id.substr(1)
            }
            let data = {
                client_id: clientID,
                grant_type: 'account',
                account: item.id,
                password: item.pass,
                nonce: nonceStr
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    let tData = jsFn.jwtDecode(res.id_token)
                    if (nonceStr === tData.nonce) {
                        resolve(res)
                    } else {
                        let result = {
                            error: 10001,
                            message: '錯誤登入'
                        }
                        reject(result)
                    }
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 社群登入
    authLogin: function(idToken, item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                client_id: clientID,
                grant_type: item.grant_type,
                open_code: item.open_code,
                nonce: nonceStr,
                redirect_uri: item.redirect_uri,
                lang: item.lang
            }
            if (!jsFn.isEmpty(idToken)) data.id_token = idToken
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    // 這個設定目前只有微信有，微信登入如果沒有帳號會得到openid
                    if(!jsFn.isEmpty(res.id_token)) {
                        let tData = jsFn.jwtDecode(res.id_token)
                        if (nonceStr === tData.nonce) {
                            resolve(res)
                        } else {
                            let result = {
                                error: 10001,
                                message: '錯誤登入'
                            }
                            reject(result)
                        }
                    // 新增教育雲判斷
                    } else if(res.open_id && res.open_id.toLowerCase().indexOf('educloudtw-') > -1) {
                        resolve(res)
                    } else {
                        let result = {
                            error: 10002,
                            message: '微信登入的新用戶，無法登入'
                        }
                        reject(result)
                    }
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 建立帳號
    crtProfile: function(item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                client_id: clientID,
                grant_type: 'create',
                account: item.account,
                name: item.name,
                password: item.password,
                pin_code: item.pin_code,
                nonce: nonceStr,
                lang: item.lang
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    let tData = jsFn.jwtDecode(res.id_token)
                    if (nonceStr === tData.nonce) {
                        resolve(res)
                    } else {
                        let result = {
                            error: 10001,
                            message: '錯誤登入'
                        }
                        reject(result)
                    }
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 發送驗證碼
    sendOTPCode: function(type, item) {
        return new Promise((resolve, reject) => {
            let funPath = type === 'phone' ? '/service/smsotp/pin' : '/service/sandmail/pin'
            let data = {
                to: item.account,
                lang: item.lang,
                HasUser: item.HasUser
            }
            if (type === 'email') {
                delete data.country
            } else {
                data.country = item.country.toString()
                data.CaptchaToken = item.CaptchaToken
            }
            post(apiUrl + funPath, data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 重置密碼
    restPw: function(item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            // 開頭有0要去0
            if (item.id.toString().indexOf(0) === 0) item.id = item.id.substr(1)

            let data = {
                client_id: clientID,
                grant_type: 'reset',
                account: item.id,
                pin_code: item.pinCode,
                password: item.pass,
                nonce: nonceStr
            }
            post(apiUrl + '/oauth2/login', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    let tData = jsFn.jwtDecode(res.id_token)
                    if (nonceStr === tData.nonce) {
                        resolve(res)
                    } else {
                        let result = {
                            error: 10001,
                            message: '錯誤登入'
                        }
                        reject(result)
                    }
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 大雲快速登入
    codeLogin: function(code) {
        return new Promise((resolve, reject) => {
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                client_id: clientID,
                grant_type: 'authorization_code',
                code: code
            }
            post(apiUrl + '/oauth2/token', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 取得詳細使用者資訊
    getUserInfo: function(idToken) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'get',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 修改名稱
    updName: function(idToken, name) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'name',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                name: name
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 修改密碼
    updPw: function(idToken, item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'password',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                old_pw: item.old_pw,
                new_pw: item.new_pw
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 修改Email Or Mail
    updMobileMail: function(idToken, item) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'mobileOrMail',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                mobile_mail: item.mobile_mail,
                pin_code: item.pin_code
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 修改大頭貼
    updPicture: function(idToken, base64File) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'picture',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                picture: base64File
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 解綁
    unBind: function(idToken, provider) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'unbind',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                provider: provider
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    delAccount: function(idToken, pw) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'delete',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                old_pw: pw
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 修改慣用語系
    updLang: function(idToken, lang) {
        return new Promise((resolve, reject) => {
            let nonceStr = jsFn.getUUID()
            if (!jsFn.isEmpty(sessionStorage.getItem('nonce'))) { nonceStr = sessionStorage.getItem('nonce') }
            if (!jsFn.isEmpty(sessionStorage.getItem('clientId'))) { clientID = sessionStorage.getItem('clientId') }
            let data = {
                grant_type: 'lang',
                client_id: clientID,
                id_token: idToken,
                nonce: nonceStr,
                lang: lang
            }
            post(apiUrl + '/oauth2/profile', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    logout: function(data) {
        return new Promise((resolve, reject) => {
            post(apiUrl + '/oauth2/logout', data).then(res => {
                if (res.error) {
                    reject(res)
                } else {
                    resolve(true)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 取得使用者進階資訊
    getUserExtend: function(idToken) {
        return new Promise((resolve, reject) => {
            let data = {
                grant_type: 'get',
                id_token: idToken,
                product: 'base-ex'
            }
            post(apiUrl + '/oauth2/usersetting', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    // 取得學校列表
    getSchoolData: function(item) {
        return new Promise((resolve, reject) => {
            let data = {
                countryId: item.country
            }

            if(item.country === 'TW') {
                data.cityId = item.province
            } else {
                data.provinceId = item.province
                data.cityId = item.city
            }

            post(apiUrl + '/service/schoolData', data).then(res => {
                if (res.error) {
                    reject(new Error(res))
                } else {
                    resolve(res)
                }
            }, err => {
                reject(err)
            })
        })
    },
    setContact: function(idToken, item) {
        return new Promise((resolve, reject) => {
            let data = {
                grant_type: 'add',
                id_token: idToken
            }

            let keys = Object.keys(item)
            keys.forEach(k => {
                data[k] = item[k]
            })

            if(data.countryCode && data.mobile) {
                data.mobile = `+${data.countryCode}-${data.mobile}`
                delete data.countryCode
            }

            delete data.areaStr

            post(apiUrl + '/oauth2/contact', data).then(() => {
                resolve(true)
            }, err => {
                reject(err)
            })
        })
    }
}
