//
//
//
//
//
//
//
//

import codeData from '@/assets/js/countryCodeData.js'
export default {
    name: 'countrycode',
    props: {
        value: {
            type: [String, Number]
        }
    },
    model: {
        prop: 'value', // 绑定的值，通过父组件传递
        event: 'update' // 自定义时间名
    },
    data() {
        return {
            lang: localStorage.getItem('lang'),
            value1: ''
        }
    },
    computed: {
        dataList() {
            let data = []
            let LowerCaseLang = this.lang.toLowerCase()
            let k = ''
            switch (LowerCaseLang) {
                case 'zh-tw':
                    k = 'CountryTw'
                    break
                case 'zh-cn':
                    k = 'CountryCn'
                    break
                case 'zh-hk':
                    k = 'CountryTw'
                    break
                default:
                    k = 'CountryEn'
                    break
            }

            Object.keys(codeData).forEach(function(key) {
                let OItem = codeData[key]
                let label
                label = '+' + OItem.Code + '(' + OItem[k] + ')'
                data.push({
                    label: label,
                    value: OItem.Code
                })
            })
            // 新增排序
            data.sort(function(a, b) {
                return a.value - b.value
            })
            return data
        }
    },
    watch: {
        value: function(newVal, oldVal) {
            if(newVal) {
                this.value1 = Number(newVal)
            }
        }
    },
    methods: {
        setValue: function(e) {
            this.$emit('update', e.value) // 子组件与父组件通讯，告知父组件更新绑定的值
        },
        def: function() {
            // let LowerCaseLang = this.lang.toLowerCase()
            // let k = Object.keys(codeData).filter((key) => {
            //     return codeData[key].Culture.toString().toLowerCase() === LowerCaseLang
            // })
            // if (k) {
            //     this.value1 = codeData[k].Code
            // }
        }
    }
}
