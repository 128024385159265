//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'userInfo',
    data() {
        return {
            testX: navigator.userAgent
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo',
            isHiTA_UA: 'isHiTA_UA',
            isAClassONE_UA: 'isAClassONE_UA'
        }),
        userPhoneNum: function() {
            let str
            if (this.userInfo.country || this.userInfo.mobile) {
                str = '+' + this.userInfo.country + '-' + this.userInfo.mobile
            }
            return str
        },
        langStr: function() {
            let str
            switch(this.userInfo.lang) {
                case 'zh-tw':
                    str = this.$t('public_14')
                break
                case 'zh-cn':
                    str = this.$t('public_15')
                break
                case 'en-us':
                    str = this.$t('public_16')
                break
            }

            return str
        }
    },
    created() {
    },
    methods: {
        ...mapActions(['logOut']),
        managementLogout: function() {
            this.$Spin.show()
            this.logOut()
        }
    }
}
