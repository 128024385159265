import { render, staticRenderFns } from "./pg-updpassword.vue?vue&type=template&id=5b6a351e&scoped=true&"
import script from "./pg-updpassword.vue?vue&type=script&lang=js&"
export * from "./pg-updpassword.vue?vue&type=script&lang=js&"
import style1 from "./pg-updpassword.vue?vue&type=style&index=1&id=5b6a351e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6a351e",
  null
  
)

export default component.exports