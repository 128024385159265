//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cropper from 'cropperjs'
import './image-editor/cropper.min.css'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'profilePicture',
    data() {
        return {
            editIMGFlag: false,
            cropper1: {},
            outPutIMG: {
                width: 200,
                height: 200
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo'
        })
    },
    created() {

    },
    methods: {
        ...mapActions(['setUserInfo']),
        restUserInfo: function() {
            document.location.href = '/management/userinfo?a=' + Math.random().toString(36).substring(7)
        },
        handleChange1(e) {
            let file = e.target.files[0]
            let reader = new FileReader()
            reader.onload = () => {
                this.editIMGFlag = true
                this.cropper1.replace(reader.result)
                reader.onload = null
            }
            reader.readAsDataURL(file)
        },
        handlecrop1() {
            let base64File = this.cropper1.getCroppedCanvas({ width: this.outPutIMG.width, height: this.outPutIMG.height }).toDataURL()
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/updPicture', base64File).then(res => {
                this.$Message.info({
                    content: this.$t('public_10'),
                    duration: 5,
                    closable: true
                })
                this.$router.push({ name: 'userinfo' })
            }).catch(() => {
                this.$Message.warning({
                    content: this.$t('error_18'),
                    duration: 5,
                    closable: true
                })
            }).finally(() => {
                this.$Spin.hide()
            })
        },
        chooseFiles: function() {
            document.getElementById('fileinput1').click()
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    },
    mounted() {
        let img1 = document.getElementById('cropimg1')
        this.cropper1 = new Cropper(img1, {
            dragMode: 'move',
            preview: '#preview1',
            restore: false,
            center: false,
            highlight: false,
            cropBoxMovable: false,
            toggleDragModeOnDblclick: false,
            aspectRatio: 1 / 1,
            minCropBoxWidth: '120',
            minCropBoxHeight: '120'
        })
    }
}
