import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apiTools from './api'
import axios from 'vue-axios'
import './assets/css/site.css'
import 'view-design/dist/styles/iview.css'
import iView from 'view-design'
import VueHead from 'vue-head'
import i18n from './i18n'
// import JSCookie from 'js-cookie'
import { sync } from 'vuex-router-sync'
import { jsFn } from '@/utils/js-fn'

import './quasar'

Vue.config.productionTip = false

// 全局API请求
Object.defineProperty(Vue.prototype, '$http', { value: axios })
Object.defineProperty(Vue.prototype, '$api', { value: apiTools })
Object.defineProperty(Vue.prototype, '$jsFn', { value: jsFn })

Vue.use(iView)
Vue.use(VueHead)

sync(store, router)

// 处理刷新的时候vuex被清空但是用户已经登录的情况
if (!jsFn.isEmpty(sessionStorage.getItem('accountState'))) {
  let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('accountState'), 'utf-8'))
  store.dispatch('setLoginState', data.loginFlag)
  store.dispatch('coreApi/setIdToken', data.id_token)
}

if (!jsFn.isEmpty(sessionStorage.getItem('userInfo'))) {
  let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('userInfo'), 'utf-8'))
  store.dispatch('coreApi/setUserInfo', data)
}

if (!jsFn.isEmpty(sessionStorage.getItem('userExtend'))) {
  let data = JSON.parse(decodeURIComponent(sessionStorage.getItem('userExtend'), 'utf-8'))
  store.dispatch('coreApi/setUserExtend', data)
}

if (!jsFn.isEmpty(localStorage.getItem('lang'))) {
  store.dispatch('setPageLang', localStorage.getItem('lang'))
}

// 登錄中間驗證，頁面需要登錄而沒有登錄的情況直接跳轉登錄
router.beforeEach((to, from, next) => {
  // console.log('to=', to.fullPath, '| from=', from.fullPath)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.loginFlag) {
      next()
    } else {
      next({ path: '/' })
    }
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
