//
//
//
//
//
//

import PageFooter from '@/views/page-footer'

export default {
    data() {
        return {
        }
    },
    components: {
      PageFooter
    }
}
