//
//
//
//
//
//
//

export default {
    name: 'error-login',
    data() {
        return {

        }
    },
    created: function() {

    },
    methods: {

    }
}
