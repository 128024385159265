//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex'
import CountryCode from '@/components/tool/countryCode/Index.vue'
import { jsFn } from '@/utils/js-fn'
import Hcaptcha from '@/components/tool/hcaptcha/Index.vue'

export default {
    name: 'updcphn',
    data() {
        // 電話驗證
        const validCellphone = (rule, value, callback) => {
            if (jsFn.isEmpty(this.country)) {
                callback(new Error(this.$t('error_6')))
            } else if (!(/^[0-9]*$/.test(value))) {
                callback(new Error(this.$t('error_7')))
            } else {
                callback()
            }
        }
        // 驗證碼驗證
        const validVCode = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        const validPw = (rule, value, callback) => {
            if(!this.userInfo.is_set_pw) {
                if (jsFn.isEmpty(value)) {
                    callback(new Error(this.$t('error_1')))
                } else if (!(/^(?=.*[a-zA-Z])(?=.*\d)[\S]{8,30}$/.test(value))) {
                    callback(new Error(this.$t('error_5')))
                } else {
                    if (this.updCPhnform.pwCheck !== '') {
                        // 对第二个密码框单独验证
                        this.$refs.updCPhnform.validateField('pwCheck')
                    }
                    callback()
                }
            } else {
                callback()
            }
        }
        const validNewPw = (rule, value, callback) => {
            if(!this.userInfo.is_set_pw) {
                if (jsFn.isEmpty(value)) {
                    callback(new Error(this.$t('error_10')))
                } else if (value !== this.updCPhnform.newPw) {
                    callback(new Error(this.$t('error_11')))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        return {
            country: '',
            sendLoading: false,
            updCPhnform: {
                cphn: '',
                pin: '',
                newPw: '',
                pwCheck:''
            },
            updCPhnRule: {
                cphn: [
                    { validator: validCellphone, trigger: 'blur' }
                ],
                pin: [
                    { validator: validVCode, trigger: 'blur' }
                ],
                newPw: [
                    { validator: validPw, trigger: 'blur' }
                ],
                pwCheck: [
                    { validator: validNewPw, trigger: 'blur' }
                ]
            },
            countdown: false, // 倒數計時中
            verfTime: 0 // 時間倒數秒數
        }
    },
    components: {
        CountryCode,
        Hcaptcha
    },
    computed: {
        ...mapState({
            verCountdownSec: state => state.verCountdownSec
        }),
        ...mapGetters({
            pageLang: 'getLang',
            userInfo: 'coreApi/getUserInfo'
        }),
        pinCodeSwitch: function() {
            let disabledFlag = false
            if (jsFn.isEmpty(this.updCPhnform.cphn) || jsFn.isEmpty(this.country)) disabledFlag = true
            return disabledFlag
        },
        sendBtnText: function() { // 發送驗證信與驗證碼字串判斷
            let timeStr = ''
            if (this.countdown) timeStr = '(' + this.verfTime + ')'

            return this.$t('public_4') + timeStr
        },
        accFormat: function() {
            let acc = this.updCPhnform.cphn
            if (this.country === 886 && acc.indexOf(0) === 0) {
                acc = acc.substr(1)
            }
            return acc
        }
    },
    methods: {
        async validHcaptcha(token) {
            this.sendLoading = true
            let data = {
                country: this.country,
                account: this.accFormat,
                lang: this.pageLang,
                HasUser: false,
                CaptchaToken: token
            }

            this.$store.dispatch('coreApi/sendOTPCode', {
                type: 'phone',
                data: data
            }).then((res) => {
                this.$Message.info({
                    content: this.$t('public_8'),
                    duration: 5
                })
                this.startCountdown()
            }).catch((err) => {
                let text = this.$t('error_16')
                if(err.status) {
                    switch (err.status) {
                        case 429:
                            text = this.$t('error_38')
                        break
                    }
                } else {
                    switch (err.error) {
                        case 2:
                            text = this.$t('error_12')
                        break
                    }
                }
                this.$Message.warning({
                    content: text,
                    duration: 7
                })
            }).finally(() => {
                this.sendLoading = false
            })
        },
        async handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面
                    let data = {
                        mobile_mail: '+' + this.country + '-' + this.accFormat,
                        pin_code: this.updCPhnform.pin
                    }
                    this.$store.dispatch('coreApi/updMobileMail', { country: this.country, mobile: this.accFormat, data: data }).then(() => {
                        data = {
                            old_pw: '',
                            new_pw: this.updCPhnform.newPw
                        }
                        this.$store.dispatch('coreApi/updPw', data)
                        this.$Message.info({
                            content: this.$t('public_10'),
                            duration: 5,
                            closable: true
                        })
                        this.$router.push({ name: 'userinfo' })
                    }).catch(() => {
                        this.$Message.warning({
                            content: this.$t('error_14'),
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide()
                    })
                }
            })
        },
        // 寄送驗證碼
        async sendVerf() {
            // 開啟hcaptcha驗證
            // this.$refs.hcaptcha.open()  // <osbert> 暫時註解，下次更換新的驗證
            // 後續驗證交由 validHcaptcha()

            this.sendLoading = true
            let data = {
                country: this.country,
                account: this.accFormat,
                lang: this.pageLang,
                HasUser: false
            }

            this.$store.dispatch('coreApi/sendOTPCode', {
                type: 'phone',
                data: data
            }).then((res) => {
                this.$Message.info({
                    content: this.$t('public_8'),
                    duration: 5
                })
                this.startCountdown()
            }).catch((err) => {
                let text = this.$t('error_16')
                if(err.status) {
                    switch (err.status) {
                        case 429:
                            text = this.$t('error_38')
                        break
                    }
                } else {
                    switch (err.error) {
                        case 2:
                            text = this.$t('error_12')
                        break
                    }
                }
                this.$Message.warning({
                    content: text,
                    duration: 7
                })
            }).finally(() => {
                this.sendLoading = false
            })
        },
        startCountdown() {
            this.countdown = true
            this.reciprocal()
        },
        reciprocal() { // 調整倒數時間至可控
            this.verfTime -= 1
            if (this.verfTime === 0) {
                this.countdown = false
                this.verfTime = this.verCountdownSec
            } else {
                // 每秒執行一次,showTime()
                setTimeout(this.reciprocal, 1000)
            }
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    },
    mounted() {
        // 驗證碼倒數時間設定
        this.verfTime = this.verCountdownSec

        // 國碼預設值
        switch(this.pageLang) {
            case 'zh-tw':
                this.country = 886
            break
            case 'zh-cn':
                this.country = 86
            break
            case 'en-us':
                this.country = 1
            break
        }
    }
}
