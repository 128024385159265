//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
export default {
    name: 'hcaptcha',
    data() {
        return {
            flag: false,
            widgetId: null,
            tokenTemp: ''
        }
    },
    components:{
        VueHcaptcha
    },
    mounted() {
        // this.renderHCaptcha()
    },
    methods: {
        renderHCaptcha() {
            // if(window.hcaptcha && window.hcaptcha.render) {
            //     this.widgetId = window.hcaptcha.render('captcha-1', {
            //         sitekey: 'ad3a1bd9-43e1-4e4c-a542-0cc1357d2a80',
            //         'error-callback': this.onError,
            //         'callback': this.onVerify,
            //         'expired-callback':this.onExpire,
            //         'chalexpired-callback':this.onChallengeExpire
            //     })
            // } else {
            //     // setTimeout(this.renderHCaptcha(), 1000)
            // }
        },
        open: function() {
            this.flag = true
            if(this.tokenTemp !== '') {
                this.tokenTemp = ''
                this.$refs.vueHCaptcha.reset() // 重置
            }
        },
        onVerify(token, eKey) {
            this.flag = false
            // console.log('Verified: ', { token, eKey })
            this.tokenTemp = token
            this.$emit('getToken', token) // 子组件与父组件通讯
        },
        onExpire() {
            console.log('Token expired')
            this.$refs.vueHCaptcha.reset() // 重置
        },
        onChallengeExpire: () => {
            console.log('Challenge expired')
        },
        onError(err) {
            console.log('Error', err)
        },
        onRendered: () => {
            console.log('rendered')
        },
        onExecuted: (res) => {
            console.log('executed', res)
        }
    }
}
