//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { jsFn } from '@/utils/js-fn'

export default {
    name: 'updbasicinfo',
    data() {
        const validateName = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        return {
            updInfoform: {
                name: ''
            },
            updInfoRule: {
                name: [
                    { validator: validateName, trigger: 'blur' }
                ]
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo'
        })
    },
    created() {
    },
    methods: {
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面
                    this.$store.dispatch('coreApi/updName', this.updInfoform.name).then(res => {
                        this.$Message.info({
                            content: this.$t('public_10'),
                            duration: 5,
                            closable: true
                        })
                        this.$router.push({ name: 'userinfo' })
                    }).catch(() => {
                        this.$Message.warning({
                            content: this.$t('error_18'),
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide()
                    })
                }
            })
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    }
}
