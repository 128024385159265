//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CountryCode from '@/components/tool/countryCode/Index.vue'
import Hcaptcha from '@/components/tool/hcaptcha/Index.vue'
import { mapState, mapGetters } from 'vuex'
import { jsFn } from '@/utils/js-fn'

export default {
    name: 'forgetpassword',
    data() {
        // 帳號格式驗證
        const validateAccount = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_1')))
            } else if (this.isPhone) {
                if (this.countryCode === '') {
                    callback(new Error(this.$t('error_6')))
                } else if (!(/^[0-9]*$/.test(value))) {
                    callback(new Error(this.$t('error_7')))
                } else {
                    callback()
                }
            } else {
                let emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
                if (value.search(emailRule) !== -1) {
                    callback()
                } else {
                    callback(new Error(this.$t('error_4')))
                }
            }
        }
        // 驗證碼格式驗證
        const validVCode = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else if (!(/^[A-Za-z0-9]+$/.test(value))) {
                callback(new Error(this.$t('error_5')))
            } else {
                callback()
            }
        }
        const validatePass = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else if (!(/^(?=.*[a-zA-Z])(?=.*\d)[\S]{8,30}$/.test(value))) {
                callback(new Error(this.$t('error_5')))
            } else {
                callback()
            }
        }
        const psCheck = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_10')))
            } else if (value !== this.vCodeForm.passwd) {
                callback(new Error(this.$t('error_11')))
            } else {
                callback()
            }
        }
        return {
            verfTime: 0, // 倒數秒數控制
            verfFlag: false,
            idFlag: false,
            vCodeForm: {
                vcode: '',
                passwd: '',
                paswdCheck: ''
            },
            vCodeRule: {
                vcode: [
                    { validator: validVCode, trigger: 'blur' }
                ],
                passwd: [
                    { validator: validatePass, trigger: 'blur' },
                    { Range: true, min: 6, max: 15, message: this.$t('error_13'), trigger: 'blur' }
                ],
                paswdCheck: [
                    { validator: psCheck, trigger: 'blur' },
                    { Range: true, min: 6, max: 15, message: this.$t('error_13'), trigger: 'blur' }
                ]
            },
            isPhone: true,
            valfIdForm: {
                loginId: ''
            },
            valfIdRule: {
                loginId: [
                        { validator: validateAccount, trigger: 'blur' }
                    ]
            },
            countryCode: '',
            phnVerStr: '',
            sendLoading: false, // 發送Loading
            countdown: false, // 倒數計時中
            isSucess: false // 修改成功
        }
    },
    mounted() {
        // 國碼預設值
        switch(this.pageLang) {
            case 'zh-tw':
                this.countryCode = 886
            break
            case 'zh-cn':
                this.countryCode = 86
            break
            case 'en-us':
                this.countryCode = 1
            break
        }
    },
    head: {
        title: function() {
            return {
                inner: this.$t('forgetPaswd_11'),
                separator: ' '
                // complement: 'My Complement'
            }
        }
    },
    computed: {
        ...mapState({
            verCountdownSec: state => state.verCountdownSec
        }),
        ...mapGetters({
            pageLang: 'getLang'
        }),
        accFormat: function() {
            let acc = this.valfIdForm.loginId
            if (this.isPhone && this.countryCode === 886 && acc.indexOf(0) === 0) {
                acc = acc.substr(1)
            }
            return acc
        },
        sendBtnText: function() { // 發送驗證信與驗證碼字串判斷
            let str = this.$t('public_11')
            let timeStr = ''
            if (this.countdown) timeStr = '(' + this.verfTime + ')'

            return str + timeStr
        },
        formetLoginId: function() {
            let id = this.accFormat.toString()
            let before3 = id.slice(0, 3)
            if(this.isPhone) {
                let after2 = id.slice(-2)
                return '+' + this.countryCode + before3 + '****' + after2
            } else {
                let idArry = id.split('@')
                return before3 + '****@' + idArry[1]
            }
        }
    },
    components: {
        CountryCode,
        Hcaptcha
    },
    created: function() {
        // 驗證碼倒數時間設定
        this.verfTime = this.verCountdownSec
    },
    methods: {
        validHcaptcha: function(token) {
            this.sendLoading = true
            let data = {
                country: this.countryCode,
                account: this.isPhone ? this.accFormat : this.valfIdForm.loginId,
                lang: this.pageLang,
                HasUser: true,
                CaptchaToken: token
            }

            this.$store.dispatch('coreApi/sendOTPCode', {
                type: this.isPhone ? 'phone' : 'email',
                data: data
            }).then(() => {
                this.$Message.info({
                    content: this.$t('public_8'),
                    duration: 5
                })
                this.idFlag = true
                this.verfFlag = true
                this.startCountdown()
            }).catch((err) => {
                let text = this.$t('error_16')
                if(err.status) {
                    switch (err.status) {
                        case 429:
                            text = this.$t('error_38')
                            break
                    }
                } else {
                    switch (err.error) {
                        case 1:
                            text = this.$t('error_23')
                            break
                    }
                }
                this.$Message.warning({
                    content: text,
                    duration: 7
                })
            }).finally(() => {
                this.sendLoading = false
            })
        },
        submit(name) {
            this.$refs[name].validate(async(valid) => {
                if (valid) {
                    switch (name) {
                        case 'valfIdForm':
                            // 開啟hcaptcha驗證 <osbert> 暫時註解，下次更換新的驗證
                            // this.$refs.hcaptcha.open()
                            // 後續驗證交由 validHcaptcha()

                            this.sendLoading = true

                            let data = {
                                country: this.countryCode,
                                account: this.isPhone ? this.accFormat : this.valfIdForm.loginId,
                                lang: this.pageLang,
                                HasUser: true
                            }

                            this.$store.dispatch('coreApi/sendOTPCode', {
                                type: this.isPhone ? 'phone' : 'email',
                                data: data
                            }).then(() => {
                                this.$Message.info({
                                    content: this.$t('public_8'),
                                    duration: 5
                                })
                                this.idFlag = true
                                this.verfFlag = true
                                this.startCountdown()
                            }).catch((err) => {
                                let text = this.$t('error_16')
                                if(err.status) {
                                    switch (err.status) {
                                        case 429:
                                            text = this.$t('error_38')
                                            break
                                    }
                                } else {
                                    switch (err.error) {
                                        case 1:
                                            text = this.$t('error_23')
                                            break
                                    }
                                }
                                this.$Message.warning({
                                    content: text,
                                    duration: 7
                                })
                            }).finally(() => {
                                this.sendLoading = false
                            })

                            break
                        case 'vCodeForm':
                            this.$Spin.show() // 開啟加載畫面
                            let idFormat
                            if (this.isPhone) {
                                idFormat = '+' + this.countryCode + '-' + this.accFormat
                            } else {
                                idFormat = this.accFormat
                            }
                            this.$store.dispatch('coreApi/restPassword', {
                                country: this.isPhone ? this.countryCode : '',
                                id: idFormat,
                                pinCode: this.vCodeForm.vcode,
                                pass: this.vCodeForm.passwd
                            }).then(() => {
                                this.vCodeForm.vcode = ''
                                this.vCodeForm.passwd = ''
                                this.vCodeForm.paswdCheck = ''
                                this.valfIdForm.loginId = ''
                                this.idFlag = false
                                this.isSucess = true
                            }).catch((e) => {
                                let str = this.$t('error_14')
                                switch (e.error) {
                                    case 10001:
                                        str = this.$t('error_27')
                                        break
                                }
                                this.$Message.warning({
                                    content: str,
                                    duration: 5,
                                    closable: true
                                })
                            }).finally(() => {
                                this.$Spin.hide()
                            })
                            break
                    }
                }
            })
        },
        startCountdown() {
            this.countdown = true
            this.reciprocal()
        },
        reciprocal() { // 調整倒數時間至可控
            this.verfTime -= 1
            if (this.verfTime === 0) {
                this.countdown = false
                this.verfTime = this.verCountdownSec
            } else {
                // 每秒執行一次,showTime()
                setTimeout(this.reciprocal, 1000)
            }
        },
        reSendVCode() { // 重新發送驗證碼
            this.submit('valfIdForm')
        }
    }
}
