import Vue from 'vue'
import Vuex from 'vuex'
import coreApi from './module/coreApi'
import { jsFn } from '@/utils/js-fn'
import apiTools from '@/api'
import profile from '@/utils/profile'

Vue.use(Vuex)

// TYPES
const PAGE_LANG = 'PAGE_LANG'
const LOGINSTATE = 'LOGINSTATE'
const SETLOGINSTATE = 'SETLOGINSTATE'
const LOGOUT = 'LOGOUT'

// clinet端的 機體資訊
var u = navigator.userAgent

// clientID
const serverLocation = jsFn.isCN() ? 'cn' : 'global'
var clientID = profile.clientID[serverLocation]

// STATE
const state = {
    pageLang: '',
    loginFlag: false,
    isApp: /Android|webOS|iPhone|iPod|BlackBerry/i.test(u),
    isHiTA_UA: u.indexOf('SGlUQU1vYmlsZQ') > -1, // 與AP組溝通後，看到此字串為"HiTA"的WebView，實際字串是SGlUQU1vYmlsZQ==, 用Base64解碼
    isAClassONE_UA: u.indexOf('QUNsYXNzT05FTW9iaWxl') > -1, // 與AP組溝通後，看到此字串為"AClassONE"的WebView，實際字串是QUNsYXNzT05FTW9iaWxl, 用Base64解碼
    verCountdownSec: 60 // 驗證碼倒數秒數
}

const getters = {
    getLang: state => state.pageLang,
    isHiTA_UA: state => state.isHiTA_UA,
    isAClassONE_UA: state => state.isAClassONE_UA,
    isApp: state => state.isApp
}

// MUTATIONS
const mutations = {
    [PAGE_LANG](state, lang) {
        state.pageLang = lang
    },
    [LOGINSTATE](state, obj) {
        state.loginFlag = obj.loginFlag
    },
    [SETLOGINSTATE](state, flag) {
        state.loginFlag = flag
    },
    [LOGOUT](state) {
        state.loginFlag = false
    }
}

// ACTIONS
const actions = ({
    setPageLang(context, params) {
        localStorage.setItem('lang', params)
        context.commit(PAGE_LANG, params)
    },
    async loginState(context) {
        let callbackUrl = sessionStorage.getItem('callback')
        let redirectUri = sessionStorage.getItem('redirect_uri')

        // 新增檢查id_token 是否有語系偏好的內容
        let jwtData = jsFn.jwtDecode(context.state.coreApi.id_token)
        if(jwtData.lang == null || jwtData.lang === undefined) {
            await context.dispatch('coreApi/setDefNoticeLang', context.state.pageLang).then(res => {
                console.log(res, 'res')
            })
        }

        if (!jsFn.isEmpty(redirectUri)) {
            let code = ''
            let isTicket = false

            await apiTools.coreApi.getCode(context.state.coreApi.id_token, isTicket).then(res => {
                code = res
            }).catch(err => {
                console.log(err)
            })

            let accountVar = '?code=' + code + '&clientId=' + sessionStorage.getItem('clientId') + '&state=' + sessionStorage.getItem('state')
            let firstQuesNum = 0 // 第一個?號的位置
            let url = '' // 返回的網址
            let variable = '' // User 的變數
            let tmp = '' // 組成的網址

            // 檢查callback 網址裡面是否變數
            if (redirectUri.indexOf('?') !== -1) { // 有
                firstQuesNum = redirectUri.indexOf('?')
                url = redirectUri.substring(0, firstQuesNum)
                variable = redirectUri.substring(firstQuesNum + 1)
                tmp = url + accountVar + '&' + variable
            } else { // 沒有
                tmp = redirectUri + accountVar
            }
            sessionStorage.removeItem('redirect_uri')
            sessionStorage.removeItem('clientId')
            sessionStorage.removeItem('state')
            sessionStorage.removeItem('nonce')

            window.location.href = tmp
        } else if (!jsFn.isEmpty(callbackUrl)) {
            let code = ''
            let isTicket = false
            if(callbackUrl.indexOf('www.habook.com') >= 0) isTicket = true

            await apiTools.coreApi.getCode(context.state.coreApi.id_token, isTicket).then(res => {
                code = res
            }).catch(err => {
                console.log(err)
            })

            let payload = jsFn.jwtDecode(context.state.coreApi.id_token)
            let accountVar = '?ticket=' + code + '&id=' + encodeURIComponent(payload.sub,'utf-8') + '&name=' + encodeURIComponent(payload.name,'utf-8') + '&idToken=' + encodeURIComponent(payload.sub,'utf-8') // 必回的變數串要放在最前面
            let firstQuesNum = 0 // 第一個?號的位置
            let url = '' // 返回的網址
            let variable = '' // User 的變數
            let tmp = '' // 組成的網址

            // 檢查callback 網址裡面是否變數
            if (callbackUrl.indexOf('?') !== -1) { // 有
                firstQuesNum = callbackUrl.indexOf('?')
                url = callbackUrl.substring(0, firstQuesNum)
                variable = callbackUrl.substring(firstQuesNum + 1)
                tmp = url + accountVar + '&' + variable
            } else { // 沒有
                tmp = callbackUrl + accountVar
            }
            sessionStorage.removeItem('callback')
            window.location.href = tmp
        } else {
            context.commit(LOGINSTATE, true)
            window.location.href = '/management/userinfo'
        }
    },
    setLoginState(context, params) {
        context.commit(SETLOGINSTATE, params)
    },
    async logOut(context, params) {
        let logoutData = { client_id: '', id: '' }
        if(jsFn.isEmpty(params)) {
            logoutData.client_id = clientID
            logoutData.id = context.state.coreApi.userInfo.id
            if(logoutData.id == null) logoutData.client_id = context.state.coreApi
            console.log(context.state.coreApi)
        } else {
            logoutData.client_id = params.client_id
            logoutData.id = params.id
        }
        await apiTools.coreApi.logout(logoutData).then(() => {
            console.log('logout true')
        }).catch(() => {
            console.log('logout false')
        })
        sessionStorage.removeItem('accountState')
        sessionStorage.removeItem('expires_in')
        sessionStorage.removeItem('userInfo')
        sessionStorage.removeItem('userExtend')
        sessionStorage.removeItem('access_token')
        context.commit(LOGOUT)

        let callbackUrl = ''
        if (!jsFn.isEmpty(sessionStorage.getItem('callback'))) {
            callbackUrl = sessionStorage.getItem('callback')
        }

        if (callbackUrl !== '') {
            window.location.href = callbackUrl
        } else {
            window.location.href = '/'
        }
    }
})

const modules = {
    coreApi
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules
})
