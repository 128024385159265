import axios from 'axios'
import { jsFn } from '@/utils/js-fn'
import profile from '@/utils/profile'

let serverLocation = jsFn.isCN() ? 'cn' : 'global'
let refreshing = false
axios.defaults.timeout = 30000 // 设置超时时长
axios.defaults.baseURL = ''

// http request 拦截器
axios.interceptors.request.use(
    async(config) => {
        let flag = checkToken()
        if (flag) {
            if (!refreshing) {
                if (sessionStorage.getItem('access_token')) {
                    await refreshToken()
                }
            }
        }

        config.data = JSON.stringify(config.data)
        if (sessionStorage.getItem('access_token')) {
            config.headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
            }
        } else {
            config.headers = {
                'Content-Type': 'application/json',
                'Authorization': ''
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 404:
                    console.log('找不到該頁面')
                    break
                case 500:
                    console.log('伺服器出錯')
                    break
                case 503:
                    console.log('服務失效')
                    break
                default:
                    console.log(`連接錯誤${error.response.status}`)
            }
        } else {
            console.log('連接到服務器失敗')
        }
        return Promise.reject(error.response)
    }
)

/** 檢查Token是否快過期 */
function checkToken() {
    if (!sessionStorage.getItem('expires_in') || sessionStorage.getItem('expires_in') === undefined) return false
    var nowTime = new Date()
    let cT = Date.parse(nowTime)
    let eT = Date.parse(sessionStorage.getItem('expires_in'))
    let oT = 0
    let btw = eT - oT - cT
    if (btw > 10 * 60 * 1000) {
        return false
    } else {
        return true
    }
}

/** 刷新token */
async function refreshToken() {
    refreshing = true
    await axios.post(profile.getApiUrl() + '/oauth2/token', {
        grant_type: 'refresh_token',
        client_id: profile.clientID[serverLocation],
        access_token: sessionStorage.getItem('access_token')
    }).then(
        res => {
            sessionStorage.setItem('access_token', res.data.access_token)
            sessionStorage.setItem('expires_in', res.data.expires_in)
            refreshing = false
        }, (err) => {
            console.log(err)
            refreshing = false
        }
    )
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
        .then(response => {
            resolve(response.data)
        })
        .catch(err => {
            reject(err)
        })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            response => {
                resolve(response.data)
            },
            err => {
                reject(err)
            }
        )
    })
}
