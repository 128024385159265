//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'manageRoot',
    head: {
        title: function() {
            return {
                inner: this.$t('teamModel'),
                separator: ' '
                // complement: 'My Complement'
            }
        }
    },
    data() {
        return {
            nowPage: ''
        }
    },
    computed: {
        ...mapGetters({
            pageLang: 'getLang'
        })
    },
    async created() {
        // 拿取個人資訊
        await this.$store.dispatch('coreApi/getUserInfo')
        // 拿取個人進階資訊
        await this.$store.dispatch('coreApi/getUserExtend')
    },
    mounted() {
        this.nowPage = this.$router.currentRoute.path.toLowerCase()
    },
    methods: {
        ...mapActions(['setUserInfo']),
        setNowPage: function(val) {
            this.nowPage = val
        }
    }
}
