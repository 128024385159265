//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { jsFn } from '@/utils/js-fn'
import CountryCode from '@/components/tool/countryCode/Index.vue'
import countrys from '@/assets/js/country.json'

export default {
    name: 'updbasicinfo',
    data() {
        // 電話驗證
        const validCellphone = (rule, value, callback) => {
            if(!jsFn.isEmpty(value)) {
                if (jsFn.isEmpty(this.extendform.countryCode)) {
                    callback(new Error(this.$t('error_6')))
                } else if (!(/^[0-9]*$/.test(value))) {
                    callback(new Error(this.$t('error_7')))
                } else if (this.extendform.countryCode === 886 && value.length < 9) {
                    callback(new Error(this.$t('error_26')))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        // Emai 驗證
        const validEmail = (rule, value, callback) => {
            if(!jsFn.isEmpty(value)) {
                let emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
                if (value.search(emailRule) !== -1) {
                    callback()
                } else {
                    callback(new Error(this.$t('error_4')))
                }
            } else {
                callback()
            }
        }
        return {
            schoolOptionFlag: false,
            maskFlag: true,
            areaOptionFlag: false,
            areaOption: [],
            schoolOption: [],
            schooltFilterText: '',
            extendform: {
                name: '',
                jobTitle: '',
                country: null,
                province: null,
                city: null,
                unitName: '',
                unitType: '',
                areaStr: '',
                schoolCode: '',
                countryCode: '',
                mobile: '',
                mail: ''
            },
            updInfoRule: {
                name: {
                    required: true,
                    message: this.$t('error_1'),
                    trigger: 'blur'
                },
                jobTitle: {
                    required: true,
                    message: this.$t('error_1'),
                    trigger: 'blur'
                },
                unitType: {
                    required: true,
                    message: this.$t('error_1'),
                    trigger: 'blur'
                },
                areaStr: {
                    required: true,
                    message: this.$t('error_1'),
                    trigger: 'blur'
                },
                unitName: {
                    required: true,
                    message: this.$t('error_1'),
                    trigger: 'blur'
                },
                mail: {
                    validator: validEmail,
                    trigger: 'blur'
                },
                mobile: {
                    validator: validCellphone,
                    trigger: 'blur'
                }
            },
            unitTypes: [
                {
                    label: this.$t('extend_1'),
                    value: '1'
                },
                {
                    label: this.$t('extend_2'),
                    value: '2'
                },
                {
                    label: this.$t('extend_3'),
                    value: '3'
                },
                {
                    label: this.$t('extend_4'),
                    value: '4'
                },
                {
                    label: this.$t('extend_5'),
                    value: '5'
                },
                {
                    label: this.$t('extend_6'),
                    value: '6'
                },
                {
                    label: this.$t('extend_7'),
                    value: '7'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo',
            userExtend: 'coreApi/getUserExtend'
        }),
        filterSchoolOption() {
            if(this.schooltFilterText !== '') {
                let text = this.schooltFilterText.toLowerCase()
                return this.schoolOption.filter(function(e) {
                    return e.name.toLowerCase().indexOf(text) >= 0
                })
            } else {
                return this.schoolOption
            }
        },
        getLayerOneList() {
            let res = []
            let lang = this.userInfo.lang
            let fun = this.getLayerTwoList
            countrys.forEach(function(c) {
                if(c.lang === lang && c.areaType === 'c') {
                    res.push({
                        value: c.countryId,
                        label: c.countryName,
                        fun: fun
                    })
                }
            })

            if(lang === 'zh-tw') {
                res.sort(function(a) {
                    if(a.label === '臺灣') {
                        return -1
                    } else {
                        return 1
                    }
                })
            }
            return res
        }
    },
    created() {
    },
    mounted() {
        if(this.userExtend) {
            let lang = this.userInfo.lang
            let keys = Object.keys(this.userExtend)
            keys.forEach(k => {
                let baseEx = this.userExtend[k]
                if(baseEx !== null && baseEx !== '') {
                    switch(k) {
                        case 'country': {
                            if(baseEx) {
                                let temp = countrys.filter(c => (c.countryId === baseEx && c.lang === lang && c.areaType === 'c'))
                                if(temp.length > 0) {
                                    this.extendform[k] = baseEx
                                    this.extendform.areaStr = temp[0]?.countryName
                                }
                            }
                            break
                        }
                        case 'province': {
                            if(baseEx) {
                                let areaType = this.extendform.country === 'TW' ? 'y' : 'p'
                                let serchKey = this.extendform.country === 'TW' ? 'city' : 'province'
                                let temp = countrys.filter(c => (
                                    c.countryId === this.extendform.country &&
                                    c[serchKey + 'Id'] === baseEx &&
                                    c.lang === lang &&
                                    c.areaType === areaType)
                                )
                                if(temp.length > 0) {
                                    this.extendform[k] = baseEx
                                    this.extendform.areaStr += `/${temp[0][serchKey + 'Name']}`
                                }
                            }
                            break
                        }
                        case 'city': {
                            if(baseEx) {
                                let areaType = this.extendform.country === 'TW' ? 'd' : 'y'
                                let serchKey = this.extendform.country === 'TW' ? 'district' : 'city'
                                let temp = countrys.filter(c => (
                                    c.countryId === this.extendform.country &&
                                    c[serchKey + 'Id'] === baseEx &&
                                    c.lang === lang &&
                                    c.areaType === areaType)
                                )
                                if(temp.length > 0) {
                                    this.extendform[k] = baseEx
                                    this.extendform.areaStr += `/${temp[0][serchKey + 'Name']}`
                                }
                            }
                            break
                        }
                        // case 'unitName':
                        //     if(this.userExtend['schoolCode']) {
                        //         this.extendform[k] = baseEx
                        //     }
                        // break
                        default:
                        this.extendform[k] = baseEx
                    }
                }
            })
        } else {
            switch(this.userInfo.lang) {
                case 'zh-tw':
                    this.extendform.countryCode = 886
                break
                case 'zh-cn':
                    this.extendform.countryCode = 86
                break
                case 'en-us':
                    this.extendform.countryCode = 1
                break
            }
        }
    },
    methods: {
        handleSubmit(name) {
            this.$refs[name].validate(async(valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面

                    if(this.extendform.countryCode.toString() === '886' && this.extendform.mobile.length === 10) {
                        this.extendform.mobile = this.extendform.mobile.toString().slice(1)
                    }

                    await this.$store.dispatch('coreApi/setUserContact', this.extendform).then(() => {
                        this.$router.push({ name: 'userinfo' })
                    }).catch(e => {
                        this.$Message.warning({
                            content: this.$t('error_18'),
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide()
                    })
                }
            })
        },
        setSchool(name, type, code) {
            this.extendform.unitName = name
            this.extendform.schoolCode = code
            if(type) {
                this.extendform.unitType = type
            }
            this.schoolOptionFlag = false
        },
        openSchoolOption() {
            this.$refs['extendform'].validateField('areaStr', async(val) => {
                if(!val) {
                    this.$Spin.show() // 開啟加載畫面
                    this.schoolOptionFlag = true
                    let data = {
                        country: this.extendform.country,
                        province: this.extendform.province,
                        city: this.extendform.city
                    }
                    await this.$store.dispatch('coreApi/getSchoolData', data).then(res => {
                        this.schoolOption = res
                    }).catch((e) => {
                        console.log(e)
                    }).finally(() => {
                        this.$Spin.hide() // 關閉加載畫面
                    })
                }
            })
        },
        openAreaOption() {
            this.areaOptionFlag = true
            this.areaOption = this.getLayerOneList
        },
        getLayerTwoList(id, name) {
            let lang = this.userInfo.lang
            let fun = this.getLayerThrList
            this.extendform.country = id
            this.extendform.areaStr = name

            // 清空
            this.extendform.province = null
            this.extendform.city = null
            this.extendform.schoolCode = null
            this.extendform.unitName = null

            if((lang !== 'en-us' && (id === 'TW' || id === 'CN')) || (lang === 'en-us' && id === 'TW')) {
                this.maskFlag = false
                let res = []
                if(this.extendform.country === 'TW') {
                    countrys.forEach(function(c) {
                        if(c.lang === lang && c.areaType === 'y' && c.countryId === id && c.provinceId === null) {
                            res.push({
                                value: c.cityId,
                                label: c.cityName,
                                fun: fun
                            })
                        }
                    })
                } else {
                    countrys.forEach(function(c) {
                        if(c.lang === lang && c.areaType === 'p' && c.countryId === id) {
                            res.push({
                                value: c.provinceId,
                                label: c.provinceName,
                                fun: fun
                            })
                        }
                    })
                }
                this.areaOption = res
            } else {
                this.areaOptionFlag = false
            }
        },
        getLayerThrList(id, name) {
            this.extendform.province = id
            this.extendform.areaStr += `/${name}`
            // 北京 天津 上海 重慶 不用往下選
            if(this.extendform.country === 'CN' && (id === '11' || id === '12' || id === '31' || id === '50')) {
                // 解開遮罩所調
                this.maskFlag = true
                // 關閉抽屜
                this.areaOptionFlag = false
            } else {
                let res = []
                let fun = this.setThrId
                let lang = this.userInfo.lang
                let country = this.extendform.country

                if(country === 'CN') {
                    countrys.forEach(function(c) {
                        if(c.lang === lang && c.areaType === 'y' && c.countryId === country && c.provinceId === id) {
                            res.push({
                                value: c.cityId,
                                label: c.cityName,
                                fun: fun
                            })
                        }
                    })
                } else {
                    countrys.forEach(function(c) {
                        if(c.lang === lang && c.areaType === 'd' && c.countryId === country && c.cityId === id) {
                            res.push({
                                value: c.districtId,
                                label: c.districtName,
                                fun: fun
                            })
                        }
                    })
                }
                this.areaOption = res
            }
        },
        setThrId(thrId, thrName) {
            this.extendform.city = thrId
            this.extendform.areaStr += `/${thrName}`

            // 解開遮罩所調
            this.maskFlag = true
            // 關閉抽屜
            this.areaOptionFlag = false
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    },
    components: {
        CountryCode
    }
}
