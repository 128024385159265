import Login from '@/components/common/login'
import ForgetPassword from '@/components/common/forgetPassword'
import UserRegist from '@/components/common/userRegist'
import Logout from '@/components/common/logout'

// Account 後台
import ManageRoot from '@/components/management/manage-root'
import PgUserInfo from '@/components/management/pg-userInfo'
import PgAuth from '@/components/management/pg-auth'
import UpdPwd from '@/components/management/pg-updpassword'
import UpdEmail from '@/components/management/pg-updemail'
import UpdCellphone from '@/components/management/pg-updcellphone'
import UpdBasicInfo from '@/components/management/pg-updbasicinfo'
import UpdLang from '@/components/management/pg-updlang'
import UpdProfilePicture from '@/components/management/pg-updProfilePicture'
import DelAccount from '@/components/management/pg-delAccount'
import ErrorLogin from '@/views/error-login'
import UpdExtend from '@/components/management/pg-updextend'

/**
 * requiresAuth: 設定這網頁是否需要先登入
 **/
export const routes = [
    { path: '/', name: 'login', component: Login, display: 'Home', meta: { requiresAuth: false } },
    { path: '/comuauth', component: UserRegist, display: 'regist', meta: { requiresAuth: false } }, // 保留此路徑預防有其他網頁還在呼叫
    { path: '/regist', component: UserRegist, name: 'regist', meta: { requiresAuth: false } }, // APP也會呼叫的路徑
    { path: '/logout', component: Logout, meta: { requiresAuth: false } },
    { path: '/forgetpassword', component: ForgetPassword, meta: { requiresAuth: false } },
    {
        path: '/management',
        component: ManageRoot,
        display: 'management',
        children: [
            { path: 'userinfo', name: 'userinfo', component: PgUserInfo, meta: { requiresAuth: true } },
            { path: 'auth', name: 'auth', component: PgAuth, meta: { requiresAuth: true } },
            { path: 'updpwd', component: UpdPwd, meta: { requiresAuth: true } },
            { path: 'updemail', component: UpdEmail, meta: { requiresAuth: true } },
            { path: 'updcellphone', component: UpdCellphone, meta: { requiresAuth: true } },
            { path: 'updbasicinfo', component: UpdBasicInfo, meta: { requiresAuth: true } },
            { path: 'updlang', component: UpdLang, meta: { requiresAuth: true } },
            { path: 'updprofilepicture', component: UpdProfilePicture, meta: { requiresAuth: true } },
            { path: 'delaccount', component: DelAccount, meta: { requiresAuth: true } },
            { path: 'updextend', component: UpdExtend, meta: { requiresAuth: true } },
            { path: '/management', redirect: '/management/userinfo', meta: { requiresAuth: true } }
        ]
    },
    { path: '/errorlogin', component: ErrorLogin, meta: { requiresAuth: false } },
    // 當 url path 不符合 router 表的時候，預設轉址到
    // 順序一定要最後面
    { path: '/*', redirect: '/' }
]
