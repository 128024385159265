//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { jsFn } from '@/utils/js-fn'
export default {
    name: 'page-footer',
    data() {
        return {
            pageLang: this.$i18n.locale
        }
    },
    computed: {
        isCN: function() {
            return jsFn.isCN()
        }
    },
    created: function() {
        this.locale = this.$i18n.locale
    },
    methods: {
        ...mapActions(['setPageLang']),
        langChange(val) {
            this.pageLang = val
            this.$i18n.locale = val
            this.setPageLang(val)
        }
    }
}
