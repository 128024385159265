//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { jsFn } from '@/utils/js-fn'

export default {
    name: 'updpwd',
    data() {
        const validOldPw = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        const validPw = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else if (!(/^(?=.*[a-zA-Z])(?=.*\d)[\S]{8,30}$/.test(value))) {
                callback(new Error(this.$t('error_5')))
            } else {
                if (value === this.updPwdform.oldPw) {
                    return callback(new Error(this.$t('error_19')))
                }

                if (this.updPwdform.pwCheck !== '') {
                    // 对第二个密码框单独验证
                    this.$refs.updPwdform.validateField('pwCheck')
                }
                callback()
            }
        }
        const validNewPw = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_10')))
            } else if (value !== this.updPwdform.newPw) {
                callback(new Error(this.$t('error_11')))
            } else {
                callback()
            }
        }
        return {
            updPwdform: {
                oldPw: '',
                newPw: '',
                pwCheck: ''
            },
            updPwdRule: {
                oldPw: [
                    { validator: validOldPw, trigger: 'blur' }
                ],
                newPw: [
                    { validator: validPw, trigger: 'blur' }
                ],
                pwCheck: [
                    { validator: validNewPw, trigger: 'blur' }
                ]
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo'
        })
    },
    methods: {
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面
                    // 修改密碼
                    let data = {
                        old_pw: this.updPwdform.oldPw,
                        new_pw: this.updPwdform.newPw
                    }
                    this.$store.dispatch('coreApi/updPw', data).then((res) => {
                        this.$Message.info({
                            content: this.$t('public_10'),
                            duration: 5,
                            closable: true
                        })
                        this.$router.push({ name: 'userinfo' })
                    }).catch((e) => {
                        let text = this.$t('error_18')
                        switch (e.error) {
                            case 1:
                                text = this.$t('error_37')
                                break
                        }
                        this.$Message.warning({
                            content: text,
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide()
                    })
                }
            })
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    }
}
