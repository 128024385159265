//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { jsFn } from '@/utils/js-fn'

export default {
    name: 'delAccount',
    data() {
        const validatePass = (rule, value, callback) => {
            if(jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        return {
            delModel: false,
            dataForm: {
                passwd: ''
            },
            dataRule: {
                passwd: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'coreApi/getUserInfo'
        })
    },
    methods: {
        ...mapActions(['logOut']),
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.delModel = true
                }
            })
        },
        iWantDel() {
            this.delModel = false
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/deleteAccount', this.dataForm.passwd).then(res => {
                 this.logOut()
            }).catch(() => {
                this.$Message.warning({
                    content: this.$t('error_37'),
                    duration: 5,
                    closable: true
                })
            }).finally(() => {
                this.$Spin.hide()
            })
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    },
    created() {
    }
}
