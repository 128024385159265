//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EventSourcePolyfill } from 'event-source-polyfill'
import { mapGetters, mapState } from 'vuex'
import QRCode from 'qrcodejs2'
import { jsFn } from '@/utils/js-fn'
import profile from '@/utils/profile'
import { Base64 } from 'js-base64'

export default {
    name: 'home',
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_1')))
            } else if (value.search(/^\+/) === 0) {
                callback(new Error(this.$t('error_26')))
            } else {
                callback()
            }
        }
        const validatePasswordCheck = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        return {
            callback: '',
            loginForm: {
                loginId: '',
                password: ''
            },
            loginRule: {
                loginId: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                password: [
                        { validator: validatePasswordCheck, trigger: 'blur' }
                    ]
            },
            ticket: '', // 快速登入票卷
            qrloginFlag: false,
            sseSurvive: false,
            esState:'',
            joinQRcode: undefined,
            isCN: jsFn.isCN(), // 是否為大陸站
            isHabookaclass: jsFn.isHabookaclass(), // 是否domain為habookaclass
            loading: false,
            redirectUrlByThird: document.location.origin + '/',
            validEducloudFlag: false,
            bindForm: {
                account: '',
                password: '',
                openId: '',
                grantType: ''
            },
            bindRules: {
                account: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePasswordCheck, trigger: 'blur' }
                ]
            },
            bindErrText: '',
            btnLoading: false,
            linkCount_1: 0,
            linkCount_2: 0
        }
    },
    head: {
        title: function() {
            return {
                inner: this.$t('pageTitle_1'),
                separator: ' '
                // complement: 'My Complement'
            }
        }
    },
    computed: {
        ...mapState({
            loginFlag: state => state.loginFlag
        }),
        ...mapGetters({
            pageLang: 'getLang',
            isHiTA_UA: 'isHiTA_UA',
            isAClassONE_UA: 'isAClassONE_UA',
            isApp: 'isApp'
        })
    },
    created: function() {
        // QrCode 模式預先啟動
        // this.qrcodrLogin() 暫時註解

        // 如果有callback 先存起來
        this.getUrlQuerCallback('callback')

        // oauth登入
        let code = this.$route.query.code === undefined ? '' : this.$route.query.code
        let state = this.$route.query.state === undefined ? '' : this.$route.query.state
        let nonce = this.$route.query.nonce === undefined ? '' : this.$route.query.nonce
        let ticket = this.$route.query.ticket === undefined ? '' : this.$route.query.ticket
        let clientId = this.$route.query.client_id === undefined ? '' : this.$route.query.client_id
        let redirectUri = this.$route.query.redirect_uri === undefined ? '' : this.$route.query.redirect_uri
        // let autoLink = this.$route.query.autolink === undefined ? '' : this.$route.query.autolink

        if (!jsFn.isEmpty(redirectUri) && !jsFn.isEmpty(clientId) && !jsFn.isEmpty(state) && !jsFn.isEmpty(nonce)) { // 醍摩豆Oauth
            // 不動作
            // 其他服務的Oauth流程
            sessionStorage.setItem('redirect_uri', redirectUri)
            sessionStorage.setItem('clientId', clientId)
            sessionStorage.setItem('state', state)
            sessionStorage.setItem('nonce', nonce)
        } else if (!jsFn.isEmpty(code) && !jsFn.isEmpty(state)) { // 社群帳號登入
            switch(state) {
                case 'facebook':
                case 'google':
                case 'wechat':
                case 'ding':
                case 'educloudtw':
                    this.putUserCode(code, state)
                break
                default:
                    this.jumpOCode(code, state)
            }
        } else if (!jsFn.isEmpty(code)) { // 大雲快速登入
            this.codeLogin(code)
        } else if (!jsFn.isEmpty(ticket)) { // 舊版票券登入一樣走Code
            this.codeLogin(ticket)
        }
        // else if (!jsFn.isEmpty(autoLink)) { // 自動連結第三方
        //     let existAry = ['facebook', 'google', 'wechat', 'ding']
        //     console.log(existAry.indexOf(autoLink), 'existAry')
        //     if(existAry.indexOf(autoLink) >= 0) {
        //         this.getProviderOauth(autoLink)
        //     } else {
        //         this.$Message.warning({
        //             content: '指定的autolink不存在',
        //             duration: 5,
        //             closable: true
        //         })
        //     }
        // }
    },
    methods: {
        getUrlQuerCallback(key) {
            let url = decodeURIComponent(window.location.href)
            if (url.indexOf(key) >= 0) {
                let callbackUrl = url.substr(url.indexOf(key) + (key.length + 1))
                this.callback = callbackUrl
                sessionStorage.setItem(key, callbackUrl)
            }
        },
        // 第三方跳轉
        getProviderOauth: function(provider) {
            document.location.href = profile.communityUrl(provider, this.redirectUrlByThird)
        },
        // 登入檢查
        loginSubmit:async function(name) {
            switch(name) {
                case 'bindForm': // 這個流程目前只有教育雲在用
                    this.$refs[name].validate(async(valid) => {
                        // 清空錯誤訊息
                        this.bindErrText = ''

                        if (valid) {
                            this.btnLoading = true // 開啟加載畫面
                            let isFail = false // 失敗flag

                            // 詢問帳號是否有效
                            await this.$store.dispatch('coreApi/login', { id: this.bindForm.account, pass: this.bindForm.password }).catch(e => {
                                isFail = true
                            })

                            if (isFail) {
                                this.bindErrText = this.$t('error_2')
                                this.btnLoading = false
                            } else {
                                await this.$store.dispatch('coreApi/bindThird', {
                                    grant_type: this.bindForm.grantType,
                                    open_code: this.bindForm.openId,
                                    redirect_uri: this.redirectUrlByThird
                                }).then(async res => {
                                    if(res.error) {
                                        let text = this.$t('homePage_model.edubind.tab1.err.text5')
                                        switch (res.error) {
                                            case 2:
                                                text = this.$t('homePage_model.edubind.tab1.err.text3')
                                                break
                                            case 13:
                                                text = this.$t('homePage_model.edubind.tab1.err.text4')
                                                break
                                        }
                                        this.bindErrText = text
                                        this.btnLoading = false
                                    } else {
                                        // 執行登入
                                        this.$store.dispatch('loginState', res)
                                    }
                                }).catch(() => {
                                    this.btnLoading = false
                                    this.$Message.warning({
                                        content: this.$t('auth_16'),
                                        duration: 5,
                                        closable: true
                                    })
                                })
                            }
                        } else {
                            this.bindErrText = this.$t('homePage_model.edubind.tab1.err.text1')
                        }
                    })
                break
                case 'douCheckLogin':
                    let _this = this
                    this.$Modal.confirm({
                        title: this.$t('homePage_model.edubind.tab2.doubleCheckModal.title'),
                        content: this.$t('homePage_model.edubind.tab2.doubleCheckModal.cont'),
                        onOk: async function() {
                            _this.btnLoading = true // 開啟加載畫面
                            _this.$store.dispatch('coreApi/authLogin', {
                                grant_type: _this.bindForm.grantType,
                                open_code: _this.bindForm.openId,
                                redirect_uri: this.redirectUrlByThird,
                                lang: this.pageLang
                            }).then(res => {
                                // 執行登入
                                _this.$store.dispatch('loginState', res)
                            }).catch((e) => {
                                _this.btnLoading = false
                                _this.bindErrText = _this.$t('homePage_model.edubind.tab1.err.text5')
                            })
                        },
                        cancelText: this.$t('homePage_model.edubind.tab2.doubleCheckModal.cancelText'),
                        okText: this.$t('homePage_model.edubind.tab2.doubleCheckModal.okText')
                    })
                break
                default:
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.$Spin.show() // 開啟加載畫面
                        this.$store.dispatch('coreApi/login', {
                            id: this.loginForm.loginId,
                            pass: this.loginForm.password
                        }).then(res => {
                            this.$store.dispatch('loginState', res)
                        }).catch(e => {
                            this.$Spin.hide()
                            this.$Message.warning({
                                content: this.$t('error_2'),
                                duration: 5,
                                closable: true
                            })
                        })
                    }
                })
            }
        },
        qrcodrLogin() {
            this.qrloginFlag = !this.qrloginFlag
            if (this.qrloginFlag && !this.sseSurvive) {
                // SSE 連線
                this.SEELink()
            }
        },
        SEELink: function() { // 開啟SSE連結
            if (!this.sseSurvive) {
                let apiUrl = profile.getQRCodeUrl()
                let es = new EventSourcePolyfill(apiUrl + '/service/sse', { headers: { 'X-Auth-Name': 'Account' } })
                let _this = this
                this.esState = es

                var closeSSE = function() {
                    if (_this.sseSurvive) {
                        es.close()
                        _this.sseSurvive = false
                    }
                }

                es.addEventListener('open', function(e) {
                    _this.sseSurvive = true
                }, false)

                // 取得登入網址
                es.addEventListener('message', function(e) {
                    if (e.data) {
                        let data = JSON.parse(e.data)
                        if (data.sid) {
                            let qrcodeURL = apiUrl + '/qrcode/qrcodelogin?sid=' + encodeURIComponent(data.sid, 'utf-8') + '&info=' + encodeURIComponent('TEAM Model', 'utf-8') + '&m=' + encodeURIComponent(_this.$t('homePage_25'), 'utf-8')
                            _this.crtQrcode(qrcodeURL)
                        } else if (data.code) {
                            closeSSE()
                            _this.codeLogin(data.code)
                        }
                    }
                }, false)

                // 錯誤
                es.addEventListener('error', function(e) {
                    closeSSE()
                }, false)

                // 設定五分鐘後關閉連接
                setTimeout(() => {
                    closeSSE()
                }, 300000)
            }
        },
        crtQrcode: function(url) { // 開啟QRCODE
            this.$nextTick(() => {
                if (this.joinQRcode === undefined) {
                    let qrcode = new QRCode('qrcode', {
                        width: 200, // 设置宽度，单位像素
                        height: 200, // 设置高度，单位像素
                        text: url // 设置二维码内容或跳转地址
                    })
                    this.joinQRcode = qrcode
                } else {
                    this.joinQRcode.clear()
                    this.joinQRcode.makeCode(url)
                }
            })
        },
        putUserCode: function(code, state) { // 如果第三方登入會啟動的function
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/authLogin', {
                grant_type: state,
                open_code: code,
                redirect_uri: this.redirectUrlByThird,
                lang: this.pageLang
            }).then(res => {
                if(res.open_id && res.open_id.toLowerCase().indexOf('educloudtw-') > -1) { // 第一次使用教育雲登入，需選擇綁定醍摩豆帳號或直接登入
                    this.$Spin.hide()
                    this.bindForm.openId = res.open_id
                    this.bindForm.grantType = res.grant_type
                    this.validEducloudFlag = true
                } else {
                    this.$store.dispatch('loginState', res)
                }
            }).catch((e) => {
                this.$Spin.hide()
                switch (e.error) {
                    case 10002:
                        this.$Modal.info({
                            title: this.$t('error_39_title'),
                            content: this.$t('error_39_content')
                        })
                        break
                    default:
                        this.$Message.warning({
                            content: this.$t('error_2'),
                            duration: 5,
                            closable: true
                        })
                }
            })
        },
        // 將open_code 轉給指定的位置
        jumpOCode: function(code, state) {
            this.$Spin.show() // 開啟加載畫面
            var stateJ
            let isError = false
            let existClientIdAry = ['4a6fd5c8-dfe2-4aef-ad9c-d5cb04488ae3']

            try {
                stateJ = JSON.parse(Base64.decode(decodeURIComponent(state)))
            } catch (error) {
                console.log(error)
            }
            console.log(stateJ, 'stateJ')

            if(
                jsFn.isEmpty(stateJ) ||
                jsFn.isEmpty(stateJ.op_redirecturi) ||
                jsFn.isEmpty(stateJ.client_id) ||
                existClientIdAry.indexOf(stateJ.client_id) === -1 ||
                /http(s)?:\/\/[A-Za-z0-9-]+\.teammodel.net/.test(stateJ.op_redirecturi) === false
            ) {
                isError = true
            }

            if(!isError) {
                let accountVar = '?open_code=' + code // 必回的變數串要放在最前面
                let firstQuesNum = 0 // 第一個?號的位置
                let url = '' // 返回的網址
                let variable = '' // User 的變數
                let tmp = '' // 組成的網址

                // 檢查callback 網址裡面是否變數
                if (stateJ.op_redirecturi.indexOf('?') !== -1) { // 有
                    firstQuesNum = stateJ.op_redirecturi.indexOf('?')
                    url = stateJ.op_redirecturi.substring(0, firstQuesNum)
                    variable = stateJ.op_redirecturi.substring(firstQuesNum + 1)
                    tmp = url + accountVar + '&' + variable
                } else { // 沒有
                    tmp = stateJ.op_redirecturi + accountVar
                }

                sessionStorage.removeItem('callback')
                window.location.href = tmp
            } else {
                this.$Message.warning({
                    content: '沒有支援',
                    duration: 5,
                    closable: true
                })
            }
            this.$Spin.hide()
        },
        codeLogin: function(code) {
            this.$Spin.show() // 開啟加載畫面
            this.$store.dispatch('coreApi/codeLogin', code).then(res => {
                this.$store.dispatch('loginState', res)
            }).catch(() => {
                this.$Spin.hide()
                this.$Message.warning({
                    content: this.$t('快速登入失敗'),
                    duration: 5,
                    closable: true
                })
            })
        },
        closeSSE() {
            if(this.sseSurvive) {
                this.esState.close()
                this.sseSurvive = false
            }
        }
    },
    mounted() {
        this.linkCount_1 = this.$refs.linkIcon_1.getElementsByTagName('li').length
        this.linkCount_2 = this.$refs.linkIcon_2.getElementsByTagName('li').length
    }
}
