//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import { jsFn } from '@/utils/js-fn'

export default {
    name: 'updemail',
    data() {
        // Emai 驗證
        const validEmail = (rule, value, callback) => {
            let emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else if (value.search(emailRule) !== -1) {
                callback()
            } else {
                callback(new Error(this.$t('error_4')))
            }
        }
        // 驗證碼驗證
        const validpin = (rule, value, callback) => {
            if (jsFn.isEmpty(value)) {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        const validPw = (rule, value, callback) => {
            if(!this.userInfo.is_set_pw) {
                if (jsFn.isEmpty(value)) {
                    callback(new Error(this.$t('error_1')))
                } else if (!(/^(?=.*[a-zA-Z])(?=.*\d)[\S]{8,30}$/.test(value))) {
                    callback(new Error(this.$t('error_5')))
                } else {
                    if (this.updEmailform.pwCheck !== '') {
                        // 对第二个密码框单独验证
                        this.$refs.updEmailform.validateField('pwCheck')
                    }
                    callback()
                }
            } else {
                callback()
            }
        }
        const validNewPw = (rule, value, callback) => {
            if(!this.userInfo.is_set_pw) {
                if (jsFn.isEmpty(value)) {
                    callback(new Error(this.$t('error_10')))
                } else if (value !== this.updEmailform.newPw) {
                    callback(new Error(this.$t('error_11')))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        return {
            sendLoading: false,
            updEmailform: {
                email: '',
                pin: '',
                newPw: '',
                pwCheck:''
            },
            updEmailRule: {
                email: [
                    { validator: validEmail, trigger: 'blur' }
                ],
                pin: [
                    { validator: validpin, trigger: 'blur' }
                ],
                newPw: [
                    { validator: validPw, trigger: 'blur' }
                ],
                pwCheck: [
                    { validator: validNewPw, trigger: 'blur' }
                ]
            },
            countdown: false, // 倒數計時中
            verfTime: 0 // 時間倒數秒數
        }
    },
    created() {
        this.verfTime = this.verCountdownSec
    },
    computed: {
        ...mapState({
            verCountdownSec: state => state.verCountdownSec
        }),
        ...mapGetters({
            pageLang: 'getLang',
            userInfo: 'coreApi/getUserInfo'
        }),
        pinCodeSwitch: function() {
            let disabledFlag = false
            if (this.updEmailform.email === '' || !jsFn.isEmail(this.updEmailform.email)) disabledFlag = true
            return disabledFlag
        },
        sendBtnText: function() { // 發送驗證信與驗證碼字串判斷
            let str = this.$t('public_7')
            let timeStr = ''
            if (this.countdown) timeStr = '(' + this.verfTime + ')'

            return str + timeStr
        }
    },
    methods: {
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面
                    let data = {
                        mobile_mail: this.updEmailform.email,
                        pin_code: this.updEmailform.pin
                    }
                    this.$store.dispatch('coreApi/updMobileMail', { country: '', mobile: '', email: this.updEmailform.email, data: data }).then(res => {
                        data = {
                            old_pw: '',
                            new_pw: this.updEmailform.newPw
                        }
                        this.$store.dispatch('coreApi/updPw', data)
                        this.$Message.info({
                            content: this.$t('public_10'),
                            duration: 5,
                            closable: true
                        })
                        this.$router.push({ name: 'userinfo' })
                    }).catch(() => {
                        this.$Message.warning({
                            content: this.$t('error_14'),
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide()
                    })
                }
            })
        },
        // 寄送驗證信
        sendVerf() {
            this.sendLoading = true
            let data = {
                account: this.updEmailform.email,
                lang: this.pageLang,
                HasUser: false
            }
            this.$store.dispatch('coreApi/sendOTPCode', {
                type: 'email',
                data: data
            }).then((res) => {
                this.$Message.info({
                    content: this.$t('public_8'),
                    duration: 5
                })
                this.startCountdown()
            }).catch((err) => {
                let text
                switch (err.error) {
                    case 2:
                        text = this.$t('error_8')
                        break
                    default:
                        text = this.$t('error_16')
                        break
                }
                this.$Message.warning({
                    content: text,
                    duration: 7
                })
            }).finally(() => {
                this.sendLoading = false
            })
        },
        startCountdown() {
            this.countdown = true
            this.reciprocal()
        },
        reciprocal() { // 調整倒數時間至可控
            this.verfTime -= 1
            if (this.verfTime === 0) {
                this.countdown = false
                this.verfTime = this.verCountdownSec
            } else {
                // 每秒執行一次,showTime()
                setTimeout(this.reciprocal, 1000)
            }
        },
        backToUserinfo() {
            this.$router.push({ name: 'userinfo' })
        }
    }
}
