//
//
//
//
//

import { mapActions } from 'vuex'
export default {
    name: 'logout',
    data() {
        return {
            callback: ''
        }
    },
    created: function() {
        // 如果有callback 先存起來
        this.getUrlQuerCallback()
        let clientId = this.$route.query.client_id === undefined ? '' : this.$route.query.client_id
        let id = this.$route.query.id === undefined ? '' : this.$route.query.id
        let logoutData = null
        if(clientId !== '' && id !== '') {
            logoutData = {client_id: clientId, id: id}
        }
        this.logOut(logoutData)
    },
    methods: {
        ...mapActions(['logOut']),
        getUrlQuerCallback() {
            let url = decodeURIComponent(window.location.href)
            if (url.indexOf('callback') >= 0) {
                let callbackUrl = url.substr(url.indexOf('callback') + ('callback'.length + 1))
                this.callback = callbackUrl
                sessionStorage.setItem('callback', callbackUrl)
            } else {
                sessionStorage.removeItem('callback')
            }
        }
    }
}
