//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { jsFn } from '@/utils/js-fn'
import CountryCode from '@/components/tool/countryCode/Index.vue'
import { mapGetters, mapState } from 'vuex'
import Hcaptcha from '@/components/tool/hcaptcha/Index.vue'
export default {
    name: 'comuAuth',
    head: {
        title: function() {
            return {
                inner: this.$t('pageTitle_2'),
                separator: ' '
                // complement: 'My Complement'
            }
        }
    },
    created() {
        // 如果有callback 先存起來
        this.getUrlQuerCallback('callback')
    },
    data() {
        const validAccount = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_1')))
            } else {
                switch (this.applyType) {
                    case 'email':
                        if (jsFn.isEmail(value)) {
                            callback()
                        } else {
                            callback(new Error(this.$t('error_4')))
                        }
                        break
                    case 'phone':
                        if (this.createAccountForm.cCode === '') {
                            callback(new Error(this.$t('error_6')))
                        } else if (!(/^[0-9]*$/.test(value))) {
                            callback(new Error(this.$t('error_7')))
                        } else {
                            callback()
                        }
                        break
                }
            }
        }
        // 姓名驗證
        const validateName = (rule, value, callback) => {
            if (value.trim() === '') {
                callback(new Error(this.$t('error_1')))
            } else {
                callback()
            }
        }
        // 電話驗證碼驗證
        const validCellPheCheck = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_1')))
            } else if (!(/^[0-9]*$/.test(value))) {
                callback(new Error(this.$t('error_7')))
            } else {
                callback()
            }
        }
         // 國家/地區驗證
        const validateCCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_6')))
            } else {
                callback()
            }
        }
        // 密碼驗證
        const RegistValidatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_9')))
            } else if (!(/^(?=.*[a-zA-Z])(?=.*\d)[\S]{8,30}$/.test(value))) {
                callback(new Error(this.$t('error_5')))
            } else {
                if (this.createAccountForm.passwdCheck !== '') {
                    // 对第二个密码框单独验证
                    this.$refs.createAccountForm.validateField('passwdCheck')
                }
                callback()
            }
        }
        // 密碼二次驗證
        const RegistValidatePassCheck = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('error_10')))
            } else if (value !== this.createAccountForm.passwd) {
                callback(new Error(this.$t('error_11')))
            } else {
                callback()
            }
        }
        return {
            callback: '',
            fillInemail: true,
            verfTime: 0, // 時間倒數秒數
            createAccountForm: { // 帳號建立欄位
                account: '', // 帳號
                name: '', // 姓名
                passwd: '', // 密碼
                passwdCheck: '', // 密碼確認
                cCode: '', // 國碼
                validCode: '' // 驗證碼
            },
            ruleValidate: {
                account: [
                    { validator: validAccount, trigger: 'blur' }
                ],
                name: [
                    { required: true, validator: validateName, trigger: 'blur' }
                ],
                cCode: [
                    { validator: validateCCode, trigger: 'blur' }
                ],
                validCode: [
                    { validator: validCellPheCheck, trigger: 'blur' }
                ],
                passwd: [
                    { required: true, validator: RegistValidatePass, trigger: 'blur' }
                ],
                passwdCheck: [
                    { required: true, validator: RegistValidatePassCheck, trigger: 'blur' }
                ]
            },
            applyType: 'phone', // 認證類型
            isCN: false, // 是大陸站嗎?
            showEmailWarning: false, // 顯示Email 警語
            countdown: false, // 倒數計時中
            agreePrivacy: false, // 同意隱私權政策
            sendLoading: false
        }
    },
    components: {
        CountryCode,
        Hcaptcha
    },
    computed: {
        ...mapState({
            verCountdownSec: state => state.verCountdownSec,
            isAndroid: state => state.isAndroid,
            isiOS: state => state.isiOS
        }),
        ...mapGetters({
            pageLang: 'getLang'
        }),
        sendBtnText: function() { // 發送驗證信與驗證碼字串判斷
            let str = (this.applyType === 'email' ? this.$t('public_7') : this.$t('public_4'))
            let timeStr = ''
            if (this.countdown) timeStr = '(' + this.verfTime + ')'

            return str + timeStr
        },
        pinCodeSwitch: function() {
            let disabledFlag = false
            if (this.createAccountForm.account === '') disabledFlag = true
            if (this.applyType === 'phone' && this.createAccountForm.cCode === '') disabledFlag = true
            if (this.applyType === 'email' && !jsFn.isEmail(this.createAccountForm.account)) disabledFlag = true

            return disabledFlag
        },
        accFormat: function() {
            let acc = this.createAccountForm.account
            if (this.applyType === 'phone' && this.createAccountForm.cCode === 886 && acc.indexOf(0) === 0) {
                acc = acc.substr(1)
            }
            return acc
        }
    },
    watch: {
        applyType: function(val) {
            // 是Email 顯示警告訊息
            val === 'email' ? this.showEmailWarning = true : this.showEmailWarning = false

            // 改變的時候重置錯誤訊息
            if (this.$refs['createAccountForm']) {
                this.$refs['createAccountForm'].fields.forEach((v, j) => {
                    v.validateDisabled = true
                    v.validateState = ''
                    v.validateMessage = ''
                })
            }
        }
    },
    mounted: function() {
        // 驗證碼倒數時間設定
        this.verfTime = this.verCountdownSec

        // 是否為大陸站
        this.isCN = jsFn.isCN()

        // 國碼預設值
        switch(this.pageLang) {
            case 'zh-tw':
                this.createAccountForm.cCode = 886
            break
            case 'zh-cn':
                this.createAccountForm.cCode = 86
            break
            case 'en-us':
                this.createAccountForm.cCode = 1
            break
        }
    },
    methods: {
        async validHcaptcha(token) {
            this.sendLoading = true
            let data = {
                country: this.createAccountForm.cCode,
                account: this.applyType === 'phone' ? this.accFormat : this.createAccountForm.account,
                lang: this.pageLang,
                HasUser: false,
                CaptchaToken: token
            }

            this.$store.dispatch('coreApi/sendOTPCode', {
                type: this.applyType,
                data: data
            }).then((res) => {
                this.$Message.info({
                    content: this.$t('public_8'),
                    duration: 5
                })
                this.startCountdown()
            }).catch((err) => {
                let text = this.$t('error_16')
                if(err.status) {
                    switch (err.status) {
                        case 429:
                            text = this.$t('error_38')
                            break
                    }
                } else {
                    switch (err.error) {
                        case 2:
                            text = this.applyType === 'phone' ? this.$t('error_12') : this.$t('error_8')
                        break
                    }
                }

                this.$Message.warning({
                    content: text,
                    duration: 7
                })
            }).finally(() => {
                this.sendLoading = false
            })
        },
        // 創建帳號
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Spin.show() // 開啟加載畫面
                    let idFormat
                    if (this.applyType === 'phone') {
                        idFormat = '+' + this.createAccountForm.cCode + '-' + this.accFormat
                    } else {
                        idFormat = this.accFormat
                    }

                    let crtData = {
                        account: idFormat,
                        name: this.createAccountForm.name,
                        password: this.createAccountForm.passwd,
                        pin_code: this.createAccountForm.validCode,
                        lang: this.pageLang
                    }
                    this.$store.dispatch('coreApi/crtUser', crtData).then(res => {
                        this.$store.dispatch('loginState', res)
                    }).catch(() => {
                        this.$Message.warning({
                            content: this.$t('error_14'),
                            duration: 5,
                            closable: true
                        })
                    }).finally(() => {
                        this.$Spin.hide() // 關閉加載畫面
                    })
                }
            })
        },
        // 發送驗證碼
        async sendVerf() {
            // 開啟hcaptcha驗證
            // this.$refs.hcaptcha.open()  // <osbert> 暫時註解，下次更換新的驗證
            // 後續驗證交由 validHcaptcha()

            this.$refs['createAccountForm'].validateField('account', (val) => {
                if(!val) {
                    this.sendLoading = true
                    let data = {
                        country: this.createAccountForm.cCode,
                        account: this.applyType === 'phone' ? this.accFormat : this.createAccountForm.account,
                        lang: this.pageLang,
                        HasUser: false
                    }

                    this.$store.dispatch('coreApi/sendOTPCode', {
                        type: this.applyType,
                        data: data
                    }).then((res) => {
                        this.$Message.info({
                            content: this.$t('public_8'),
                            duration: 5
                        })
                        this.startCountdown()
                    }).catch((err) => {
                        let text = this.$t('error_16')
                        if(err.status) {
                            switch (err.status) {
                                case 429:
                                    text = this.$t('error_38')
                                    break
                            }
                        } else {
                            switch (err.error) {
                                case 2:
                                    text = this.applyType === 'phone' ? this.$t('error_12') : this.$t('error_8')
                                break
                            }
                        }

                        this.$Message.warning({
                            content: text,
                            duration: 7
                        })
                    }).finally(() => {
                        this.sendLoading = false
                    })
                }
            })
        },
        startCountdown() {
            this.countdown = true
            this.reciprocal()
        },
        reciprocal() { // 調整倒數時間至可控
            this.verfTime -= 1
            if (this.verfTime === 0) {
                this.countdown = false
                this.verfTime = this.verCountdownSec
            } else {
                // 每秒執行一次,showTime()
                setTimeout(this.reciprocal, 1000)
            }
        },
        getUrlQuerCallback(key) {
            let url = decodeURIComponent(window.location.href)
            if (url.indexOf(key) >= 0) {
                let callbackUrl = url.substr(url.indexOf(key) + (key.length + 1))
                this.callback = callbackUrl
                sessionStorage.setItem(key, callbackUrl)
            }
        }
    }
}
